import React, { lazy, Suspense } from 'react';
import { Busy } from './core/busy';

/**
 * Renders routers lazily.
 */
const TopRouter: React.FC = () => {
  const MainRouter = lazy(() => import('./MainRouter'));
  const SignAgreementView = lazy(() => import('./sign_agreement/SignAgreementView'));

  return (
    <Suspense fallback={(<Busy className="suspense" />)}>
      {
        document.location.hostname.startsWith('signera.') || document.location.hostname.startsWith('xn--frslag-wxa.') || document.location.hostname.startsWith('192.') ? (
          <SignAgreementView />
        ) : (
          <MainRouter />
        )
      }
    </Suspense>
  );
}

export default TopRouter;