import React from 'react';
import cls from 'classnames';
import styles from './busy.module.scss';

type Props = {
  className?: string;
}

/**
 * Indicates an asynchronous task.
 */
export const Busy: React.FC<Props> = ({ className }) => (
  <div className={cls([styles.busy, className])}></div>
)