import Cookie from 'js-cookie'

const accessTokenKey = 's2_utoken'
const userIdKey = 's2_uid'
const usernameKey = 's2_uname'

/**
 * @returns The stored access token.
 */
export function getAccessToken() {
  var token = Cookie.get(accessTokenKey);
  return token
}

export function getUserId() {
  var userId = Cookie.get(userIdKey);
  return userId
}

export function getUsername() {
  var username = Cookie.get(usernameKey);
  return username
}

export function setUsername(username: string) {
  return Cookie.set(usernameKey, username);
}

/**
 * Stores an access token.
 */
export function setAccessToken(accessToken: string) {
  if (!accessToken) {
    Cookie.remove(accessTokenKey)
  } else {
    Cookie.set(accessTokenKey, accessToken);
  }
}

/**
 * Clears stored user credentials (logs out.)
 */
export async function clearUserCredentials() {
  //  TODO: clear server-side? Hence async
  Cookie.remove(accessTokenKey);
  Cookie.remove(userIdKey);
  Cookie.remove(usernameKey);
}