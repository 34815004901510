import { companyConfig } from "./config";

/**
 * Creates and appends meta tags.
 */
export function createAppMeta() {
  const base = '/' + companyConfig.resourcesDirectory.replace(/\//g, '');

  document.head.innerHTML += `
    <link rel="apple-touch-icon" sizes="180x180" href="${base}/apple-touch-icon.png">
    <link rel="icon" type="image/png" sizes="32x32" href="${base}/favicon-32x32.png">
    <link rel="icon" type="image/png" sizes="16x16" href="${base}/favicon-16x16.png">
    <link rel="manifest" href="${base}/site.webmanifest">
    <link rel="mask-icon" href="${base}/safari-pinned-tab.svg" color="#5bbad5">
    <meta name="msapplication-TileColor" content="#b91d47">
    <meta name="theme-color" content="${companyConfig.themeColor}">
    <link rel="apple-touch-icon" href="${base}/logo192.png" />
  `;
}