import "./log_monitor";
import "./log_timestamps"; // Importing this after log_monitor causes timestamps to be included in monitored logs.

import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import TopRouter from './components/TopRouter';
import './style/style.scss';
import 'moment/locale/sv';
import { createAppMeta } from './app_meta';

createAppMeta();

moment.locale('sv');

ReactDOM.render(
  <React.StrictMode>
    <TopRouter />
  </React.StrictMode>,
  document.getElementById('root')
);
