import moment from "moment";

const separator = '-';
const browserTabId = ['tab', Date.now(), Math.random().toString(24).substring(2)].join(separator);

/**
 * @returns The ID of the current browser tab.
 */
export function getTabId() {
  return browserTabId;
}

type TabMetadata = { name: string; hidden: boolean };

/**
 * @returns whether all open tabs are each `document.hidden`
 */
export function areAllTabsHidden() {
  return listTabs().every(tab => tab.hidden);
}

function listTabs(): TabMetadata[] {
  let metadata: TabMetadata[] = [];
  for (const name in localStorage) {
    if (!name.startsWith('tab-')) {
      continue;
    }

    const [prefix, ts] = name.split('-');

    if (moment().diff(new Date(Number(ts)), 'days') > 7) {
      //  Clean up.
      localStorage.removeItem(name);
      continue;
    }

    try {
      metadata.push({
        name,
        ...JSON.parse(localStorage[name])
      });
    } catch (error) {
      console.error("Error parsing tab metadata %s", localStorage[name], error);
    }
  }

  return metadata;
}

function saveState() {
  localStorage.setItem(browserTabId, JSON.stringify({ hidden: document.hidden }));
}

saveState();
document.addEventListener('visibilitychange', saveState);

window.addEventListener('unload', () => {
  localStorage.removeItem(browserTabId);
});

window.addEventListener('beforeunload', () => {
  localStorage.removeItem(browserTabId);
});