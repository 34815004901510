import 'whatwg-fetch';
import { getAccessToken } from './credentials';

const { location } = document;

export const isLocalhost = ['127.0.0.1', 'localhost'].includes(location.origin);
export const isAlpha = location.origin.indexOf('elipt.is') != -1;

let origin = location.origin;
if (isAlpha) {
  //  The schema may be resolved to http if embedded (such as during tests).
  origin = origin.replace('http://', 'https://');
}

export type Method = 'get' | 'post' | 'put' | 'delete';

const urlMaxLength = 2047;

let signals: { [request: string]: AbortController } = {};

/**
 * Creates a URL for requesting API.
 */
export function getUrl(path: string, query = null, opts = new RequestOptions()) {
  path = path || "";
  query = query || "";

  if (!path.startsWith("/")) {
    path = `/${path}`;
  }

  if (query && typeof query === "string" && !query.startsWith("?")) {
    query = `?${query}`;
  }
  if (query && typeof query !== "string") {
    //  convert obj to string
    let queryStr = ''
    let delimiter = '?'
    for (let key of Object.keys(query)) {
      if (query[key] == null) {
        continue;
      }

      let strVal = query[key];

      if (Array.isArray(strVal)) {
        if (!strVal.length) {
          continue;
        }
        strVal = strVal.join(',');
      }

      queryStr += `${delimiter}${key}=${encodeURIComponent(strVal)}`
      delimiter = '&'
    }

    query = queryStr;
  }

  let baseUrl = {
    "users": '/api/users',
    "orders": '/api/orders',
    "stock": '/api/stock',
  }[opts.api] || '/api';

  const concatenatedUrl = () => `${urlWithoutQuery}${query}`;

  const urlWithoutQuery = `${baseUrl}${path}`
  let url = concatenatedUrl();

  if (url.length > urlMaxLength && opts.truncateQuery !== false
    && urlWithoutQuery.length < urlMaxLength && url.length > urlMaxLength) {
    url = url.substr(0, urlMaxLength);
  }

  return url;
}

/**
 * Performs a standard backend request.
 * Expects the result as JSON!
 */
export async function request(path: string, method: Method = 'get', body = null, query = null, opts = new RequestOptions()) {
  const injectStatus = opts.injectStatus !== false;

  let isFormData = body instanceof FormData;

  if (body && typeof body == "object" && !isFormData) {
    body = JSON.stringify(body);
  }

  if (["get", "delete"].indexOf(method) != -1) {
    body = undefined;
  }

  let accessToken = getAccessToken();

  const url = getUrl(path, query, opts);

  const controller = opts.abortPrevious ? abortSignal(url, method) : null;

  let result = await fetch(url, {
    method,
    signal: controller?.signal,
    headers: {
      ...(accessToken ? {
        "Authorization": `Bearer ${accessToken}`,
      } : {}),
      ...(
        isFormData ? {} : {
          "Content-Type": "application/json"
        }
      ),
      ...(opts.headers || {})
    },
    body
  });

  const contentType = result.headers.get("Content-Type");

  switch (contentType) {
    case 'application/json':
    case 'application/json; charset=utf-8':
      let obj = await result.json();

      if (injectStatus) {
        obj.status = result.status;
        obj.statusText = result.statusText;
      }

      if (!result.ok) {
        let error = new Error();

        if ("localizedError" in obj) {
          error.message = obj.localizedError;
        } else if ("error" in obj) {
          error.message = obj.error || obj.message;
        } else {
          error.message = method + " request failed: " + result.statusText;
        }

        let restProps = {
          ...obj
        }
        delete restProps.message
        delete restProps.error
        for (let prop in restProps) {
          error[prop] = restProps[prop];
        }

        if (!injectStatus) {
          delete (error as any).status;
        }

        console.log("Throwing error", error)

        throw error;
      }

      return obj;

    default:
      if (!result.ok) {
        let error = new Error(method + " request failed: " + result.statusText);
        if (injectStatus) {
          (error as any).status = result.status;
        }
        throw error;
      }

      if (contentType === 'application/pdf' || contentType.startsWith('image/')) {
        return await result.blob();
      }

      let text = await result.text();

      if (injectStatus) {
        return {
          text,
          status: result.status
        }
      }

      return text;
  }
}

export class RequestOptions {
  headers?= {};
  injectStatus?= true;
  redirectToLogin?= true;
  api?: 'users' | 'orders' | 'stock';
  truncateQuery?: boolean;

  /**
   * Aborts any ongoing requests with the same URL and method.
   */
  abortPrevious?: boolean;
}

function abortSignal(url: string, method: string): AbortController {
  const token = `${method}${url}`;
  if (signals[token]) {
    signals[token].abort();
  }

  return signals[token] = new AbortController();
}