export function localizedJoin(values: string[]): string {
  return values.map((value, i) => {
    if (i === 0) {
      return value;
    }

    if (i === values.length - 1) {
      return ` och ${value}`;
    }

    return `, ${value}`;
  }).join('');
}

/**
 * Truncates a string if its length is greater than `maxLength`
 */
export function truncate(string: string, maxLength: number, tail = '...') {
  if (typeof string != 'string' || string.length + tail.length <= maxLength) {
    return string;
  }

  return string.substr(0, maxLength - tail.length) + tail;
}