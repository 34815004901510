import moment from "moment";

/**
 * Overrides console.log, console.warn, console.error, console.info, and console.debug.
 * 
 * Logs will start with a timestamp in local time.
 */
export function enableLogTimestamps() {
  const consoleFunctions = {
    log: console.log.bind(console),
    info: console.info.bind(console),
    warn: console.warn.bind(console),
    error: console.error.bind(console),
    debug: (console.debug || console.log).bind(console)
  };

  for (const fn in consoleFunctions) {
    console[fn] = function () {
      let args = [...arguments];
      if (typeof args[0] === 'string') {
        args[0] = '%s ' + args[0];
      } else {
        args = ['%s', ...args];
      }

      return consoleFunctions[fn](args[0], moment().format('YYYY-MM-DD HH:mm:ss'), ...args.slice(1));
    };
  }
}

enableLogTimestamps();