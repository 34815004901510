import { getEnvironment } from "./components/core/environment_indicator";

const targetVersion = 7;

export class AppConfig {
  stockGroups: {
    subscriptionsId: string;
    devicesId: string;
    energyId: string;
  };

  stockItems: {
    energyAgreementDefaultIds: string[];
  };

  orderFields: {
    agreementKeyId: string;
    clientNameId: string;
    clientSsnId: string;
    clientAddressId: string;
    clientCredibilityId: string;
    portedPhoneId: string;
    contactPhoneId: string;
  };

  demo?: boolean;

  version: number;
};

let _config: AppConfig;

const { hostname } = document.location;

try {
  _config = JSON.parse(localStorage.getItem('lingon_config'));

  if (hostname === 'localhost') {
    _config = {
      "stockGroups": {
        "energyId": "6655cebd8bb2883db776b125",
        "devicesId": "63dcf28e19cd9b1ae05a87de",
        "subscriptionsId": "63dbc6e819cd9b1ae05a87d7"
      },
      "stockItems": {
        "energyAgreementDefaultIds": ["6655cec68bb2883db776b126"]
      },
      "orderFields": {
        "agreementKeyId": "61640fce547d1c0032c0fdc6",
        "clientSsnId": "66386fff507b4a5099553442",
        "clientNameId": "65800106e4ff5c36c7cbf0df",
        "clientCredibilityId": "61649740567bd8eb3027a88e",
        "clientAddressId": "5f02f5fb93726c00237ea199",
        "contactPhoneId": "64102c87d9b865abae6d8a8c",
        "portedPhoneId": "5e8ccd5f9ff11e0023e98d82"
      },
      "version": targetVersion
    };
  }

  if (hostname.includes('xn--f-1fa.lingon') || hostname.includes('mycall.lingon') || hostname.includes('p94.lingon') || hostname.includes('tellme.lingon') || hostname.includes('alvascc.lingon') || hostname.includes('empire.lingon') || hostname.includes('daniel.lingon') || hostname.includes('adam.lingon') || hostname.includes('olsson.lingon') || hostname.includes('josef.lingon') || hostname.includes('ace.lingon') || hostname.includes('cbt.lingon') || hostname.includes('k2s.lingon')) {
    throw new Error('Forcing default config');
  }

  if (_config?.version !== targetVersion) {
    throw new Error('Incompatible version, creating default');
  }

  _config.demo = getEnvironment() !== 'prod';
} catch (e) {
  let devicesId = '61d27c227b413a002919a337';
  let subscriptionsId = '61d27c147b413a002919a334';
  let energyId = '62164180e760fa00296dd0e9';
  let energyAgreementDefaultIds: string[] = ['669783a90f63cf8768a5bdba']; // Energi M49 60Månader AVPFörvaltat Miljö Elavtal

  if (hostname.includes('xn--f-1fa.lingon')) {
    devicesId = '612612e218e4910028e5230d';
    subscriptionsId = '612612bf18e4910028e5229f';
  }

  if (hostname.includes('mycall.lingon')) {
    devicesId = '61f859429b44d00033993308';
    subscriptionsId = '61f859379b44d000339932fe'
  }

  if (hostname.includes('p94.lingon')) {
    devicesId = '616723d067cd07003368a177';
    subscriptionsId = '616723c967cd07003368a16f';
  }

  if (hostname.includes('tellme.lingon')) {
    devicesId = '61813d91140fb80029d3729d';
    subscriptionsId = '61813d88140fb80029d3726d'
  }

  if (hostname.includes('alvascc.lingon')) {
    devicesId = '61f856be9b44d000339930a7';
    subscriptionsId = '61f856b69b44d000339930a4'
  }

  if (hostname.includes('empire.lingon')) {
    devicesId = '619cb7eb6c47df0028d19ab7';
    subscriptionsId = '619cb7e36c47df0028d19a88';
  }

  if (hostname.includes('daniel.lingon')) {
    devicesId = '61d27c4f7b413a002919a33f';
    subscriptionsId = '61d27c467b413a002919a33d';
  }

  if (hostname.includes('adam.lingon')) {
    devicesId = '61d27c757b413a002919a344';
    subscriptionsId = '61d27c6c7b413a002919a342';
  }

  if (hostname.includes('olsson.lingon')) {
    devicesId = '61d27c897b413a002919a349';
    subscriptionsId = '61d27c7c7b413a002919a346';
  }

  if (hostname.includes('josef.lingon')) {
    devicesId = '61d27ca17b413a002919a34e';
    subscriptionsId = '61d27c977b413a002919a34c';
  }

  if (hostname.includes('ace.lingon')) {
    devicesId = '61dc2b707b413a00292731e4';
    subscriptionsId = '61dc2b637b413a00292731b6';
  }

  if (hostname.includes('cbt.lingon')) {
    devicesId = '61df36547b413a00292d971c';
    subscriptionsId = '61df364c7b413a00292d9713';
  }

  if (hostname.includes('k2s.lingon')) {
    devicesId = '61e6f2ce7b413a00293828ab';
    subscriptionsId = '61e6f2c67b413a0029382808';
  }

  if (hostname.includes('aldovo.lingon')) {
    devicesId = '620146176cf8cb002995837a';
    subscriptionsId = '620146096cf8cb002995832e';
  }

  if (hostname.includes('abonnix.lingon')) {
    subscriptionsId = '65fc7283b8d543df77218186'; // Abonnix Aktiv
  }

  if (hostname.includes('dingo.lingon')) {
    subscriptionsId = '65fc723db8d543df77218184'; // Dingo Aktiv
  }

  _config = {
    stockGroups: {
      energyId,
      devicesId,
      subscriptionsId
    },
    stockItems: {
      energyAgreementDefaultIds
    },
    orderFields: {
      agreementKeyId: '60d7154f8374ec0b5d23afcf',
      clientSsnId: '60d715cb8374ec0b5d23afd1',
      clientNameId: '60d715938374ec0b5d23afd0',
      clientCredibilityId: '60d7160a8374ec0b5d23afd3',
      clientAddressId: '60d715f88374ec0b5d23afd2',
      contactPhoneId: '60d7166a8374ec0b5d23afd5',
      portedPhoneId: '60d716528374ec0b5d23afd4'
    },
    version: targetVersion
  };
  localStorage.setItem('lingon_config', JSON.stringify(_config));
}

export const defaultConfig = _config;

type CompanyConfig = {
  company: string;
  companyShort: string;
  email: string;
  phone: string;
  website: string;
  agreementWebsite: string;
  agreementSignWebsite: string;
  logoSrc: string;
  resourcesDirectory: string;
  themeColor: string;
};

let _companyConfig: CompanyConfig;

if (document.location.hostname.includes('smultronmobil')) {
  _companyConfig = {
    company: 'Smultron Mobil',
    companyShort: 'Smultron',
    email: 'support@smultronmobil.se',
    phone: '077 51 70 088',
    website: 'https://smultronmobil.se',
    agreementWebsite: 'https://avtal.smultronmobil.se',
    agreementSignWebsite: 'https://förslag.smultronmobil.se',
    logoSrc: require('./logos/smultron_logo.png').default,
    resourcesDirectory: 'smultron',
    themeColor: '#E42328'
  };
} else {
  _companyConfig = {
    company: 'Lingon Mobil',
    companyShort: 'Lingon',
    email: 'support@lingon.net',
    phone: '060 60 66 203',
    website: 'https://lingon.se',
    agreementWebsite: 'https://avtal.lingon.se',
    agreementSignWebsite: 'https://förslag.lingon.se',
    logoSrc: require('./logos/lingon_logo.png').default,
    resourcesDirectory: 'lingon',
    themeColor: '#E42328'
  };
}

export const companyConfig = _companyConfig;