import React from 'react';
import cls from 'classnames';
import styles from './environment_indicator.module.scss';

/**
 * Renders a small indicator for the current app environment (dev, prod etc.)
 * @author Johan Svensson
 */
export default function EnvironmentIndicator() {
  const env = getEnvironment();

  if (env == 'prod') {
    return null;
  }

  return (
    <div className={cls([styles.indicator, styles[env]])}>
      {env.toUpperCase()}
    </div>
  )
}

export function getEnvironment() {
  const { hostname } = document.location;
  if (hostname == 'localhost' || hostname.endsWith('.local')) {
    return 'dev';
  }

  if (hostname.endsWith('elipt.is')) {
    return 'alpha';
  }

  return 'prod';
}